<template>
	<v-snackbar
		text
		right
		top
		timeout="2000"
		:value="!!status"
		:color="colors[status]"
	>
		{{ text[status]}}
	</v-snackbar>
</template>

<script>
export default {
	name: 'StatusSnackbar',

	props: {
		status: {
			type: String,
			default: ''
		},
		text: {
			type: Object,
			default: () => ({
				success: '',
				failed: ''
			})
		}
	},

	data () {
		return {
			colors: {
				success: 'green08',
				failed: 'strongRed'
			}
		};
	}
};
</script>
