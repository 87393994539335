<template>
	<div class="outlined-select">
		<div
			class="outlined-select-control"
			:class="{'control-active': showList}"
			@click="showList = !showList"
		>
			{{ selectedItem.label || label }}
			<span
				v-if="!showList"
				class="material-icons-outlined"
			>
				expand_more
			</span>
			<span
				v-else
				class="material-icons-outlined"
			>
				expand_less
			</span>
		</div>
		<ul
			v-if="showList"
			class="outlined-select-list"
		>
			<li
				v-for="item in items"
				:key="item.id"
				@click="selectItem(item)"
			>
				{{ item.label }}
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'OutlinedSelect',

	props: {
		value: {
			type: Object || String || Number || null,
			default: null
		},
		label: {
			type: String,
			default: ''
		},
		items: {
			type: Array,
			default: () => []
		},
		returnObject: {
			type: Boolean,
			default: false
		}
	},

	data () {
		return {
			showList: false
		};
	},

	computed: {
		selectedItem () {
			const selectedItemId = this.returnObject ? this.value?.id : this.value;
			return this.items.find((item) => item.id === selectedItemId) || {};
		}
	},

	methods: {
		selectItem (item) {
			this.$emit('input', this.returnObject ? item : item.id);
			this.showList = false;
		}
	}
};
</script>

<style lang="scss">
@import "@/scss/_colors.scss";

.outlined-select {
  padding: 8px;
}

.outlined-select-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $gray-600;
  border-radius: 0;
  padding: 4px 8px;
  background-color: white;
  color: $gray-600;

  &:hover {
    cursor: pointer;
    border-color: $meta-gray;
    color: $meta-gray;
  }

  &.control-active {
    border-color: $meta-gray;
    color: $meta-gray;
  }
}

.outlined-select-list {
  margin-top: 8px;
  padding: 4px !important;
  height: 30vh;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
  border: 1px solid $gray-600;
  background-color: white;
  color: $gray-600;

  li {
    padding: 4px;

    &:hover {
      cursor: pointer;
      background-color: $gray-100;
    }
  }
}
</style>

