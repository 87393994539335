<template>
	<div :class="{'hidden-select': showMenu}">
		<div
			class="hidden-select-label pa-1"
			:class="{'hidden-select-label-inactive': !showMenu}"
			@click="showMenu = true"
		>
			<v-icon dense class="material-icons-outlined black--text">place</v-icon>
			{{ selectedName }}
			<v-icon dense class="material-icons-outlined hidden-select-label-edit">edit</v-icon>
		</div>
		<div v-if="showMenu" class="px-4 py-2">
			<OutlinedSelect
				class="mx-n2"
				:label="label"
				:items="items"
				v-model="temporallySelectedValue"
				return-object
			/>
			<div class="d-flex justify-end">
				<v-btn
					tile
					class="_blue _small"
					@click="onCancel"
				>
					Cancel
				</v-btn>
				<v-btn
					tile
					class="_blue _small ml-2"
					:class="{'_disabled': hideSave}"
					@click="onSave"
				>
					Save
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import OutlinedSelect from '@/components/OutlinedSelect';

export default {
	name: 'HiddenSelect',

	components: {
		OutlinedSelect
	},

	props: {
		value: {
			type: Object || null,
			default: null
		},
		placeholder: {
			type: String,
			required: true
		},
		label: {
			type: String,
			default: ''
		},
		items: {
			type: Array,
			default: () => []
		}
	},

	data () {
		return {
			showMenu: false,
			temporallySelectedValue: null
		};
	},

	computed: {
		selectedName () {
			if (this.showMenu) return this.placeholder;

			const selectedItem = this.items.find((i) => i.id === this.value?.id);
			return selectedItem?.label || this.placeholder;
		},
		hideSave () {
			return !this.temporallySelectedValue || this.temporallySelectedValue?.id === this.value?.id;
		}
	},

	methods: {
		onCancel () {
			this.temporallySelectedValue = null;
			this.showMenu = false;
		},
		onSave () {
			this.$emit('input', this.temporallySelectedValue);
			this.temporallySelectedValue = null;
			this.showMenu = false;
		}
	}
};
</script>

<style lang="scss">
@import "@/scss/_colors.scss";

.hidden-select {
  width: 300px;
  background-color: $gray-100;
}

.hidden-select-label {
  color: $meta-gray;

  .hidden-select-label-edit {
    &.material-icons-outlined {
      color: transparent;
    }
  }
}

.hidden-select-label-inactive {
  width: max-content;

  &:hover {
    background-color: $gray-100;
    cursor: pointer;

    .hidden-select-label-edit {
      &.material-icons-outlined {
        color: $gray-600;
      }
    }
  }
}
</style>
