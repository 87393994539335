<template>
	<v-container fluid class="user-settings">
		<v-row>
			<v-col cols="12" sm="2"/>

			<v-col cols="12" sm="10" class="pt-10 pb-4 px-8">
				<v-row>
					<v-col class="user-settings-header content-col-container" cols="12">
						User Settings
					</v-col>
				</v-row>

				<v-row>
					<v-col class="user-settings-info d-flex align-top pa-8">
						<input
							type="file"
							accept="image/png, image/jpeg, image/jpg"
							ref="avatarUpload"
							style="display: none"
							@change="onChangeImage"
						/>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-img
									class="user-settings-info-avatar rounded-circle flex-grow-0"
									:class="{ 'bg-orange': !user.image}"
									width="88"
									height="88"
									:src="user.image && `data:image/*;base64,${user.image}`"
									@click="$refs.avatarUpload.click()"
									v-bind="attrs"
									v-on="on"
								></v-img>
							</template>
							<span>Change avatar</span>
						</v-tooltip>

						<div class="ml-8">
							<p class="user-settings-info-name mb-0">{{ user.name }}</p>
							<p class="user-settings-info-email mb-2">{{ user.login }}</p>
							<HiddenSelect
								class="user-settings-info-location"
								placeholder="Add location"
								label="Select Country"
								:items="countries"
								:value="user.market"
								@input="updateUserLocation($event)"
							></HiddenSelect>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<StatusSnackbar
			:status="userLocationUpdateStatus"
			:text="userLocationUpdateStatusText"
		/>

		<StatusSnackbar
			:status="userAvatarUpdateStatus"
			:text="userAvatarUpdateStatusText"
		/>

		<v-alert
			:value="!!userAvatarUpdateError"
			@input="setUserAvatarUpdateError('')"
			type="error"
			dismissible
			max-width="500"
			tile
			outlined
		>
			{{ userAvatarUpdateError}}
		</v-alert>
	</v-container>
</template>

<script>
import HiddenSelect from '@/components/HiddenSelect';
import StatusSnackbar from '@/components/StatusSnackbar';

const { mapState, mapMutations, mapActions } = require('vuex');

const MAX_IMAGE_SIZE_IN_BYTES = 2097152;

export default {
	name: 'UserSettings',

	components: {
		HiddenSelect,
		StatusSnackbar
	},

	data () {
		return {
			userLocationUpdateStatusText: {
				success: 'Location successfully added',
				failed: 'Failed to update location'
			},
			userAvatarUpdateStatusText: {
				success: 'Avatar successfully updated',
				failed: 'Failed to update avatar'
			}
		};
	},

	computed: {
		...mapState({
			user: (state) => state.general.user,
			userLocationUpdateStatus: (state) => state.general.userLocationUpdateStatus,
			userAvatarUpdateStatus: (state) => state.general.userAvatarUpdateStatus,
			userAvatarUpdateError: (state) => state.general.userAvatarUpdateError,
			countries: (state) => state.userSettings.countries
		})
	},

	beforeMount () {
		if (!this.countries.length) {
			this.loadCountries();
		}
	},

	methods: {
		...mapMutations('general', [
			'setUserAvatarUpdateError'
		]),
		...mapActions('general', [
			'updateUserLocation',
			'updateUserAvatar'
		]),
		...mapActions('userSettings', [
			'loadCountries'
		]),
		onChangeImage (event) {
			if (!event.target.files.length) return;

			const file = event.target.files[0];

			const checked = this.checkFile(file);
			if (checked.error) {
				this.setUserAvatarUpdateError(checked.error);
				return;
			}

			this.updateUserAvatar(file);
		},
		checkFile (file) {
			const hasAcceptedExtension = /\.(jpe?g|png)$/i.test(file.name);
			if (!hasAcceptedExtension) {
				return { error: 'PNG, JPEG, or JPG images are expected.' };
			}
			if (file.size > MAX_IMAGE_SIZE_IN_BYTES) {
				return { error: 'Please upload a file under 2MB.' };
			}
			return { ok: true };
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.user-settings {

  &-header {
    color: $meta-blue;
    font-size: 40px;
    font-weight: 400;
  }

  &-info {
    border: 1px solid #D0D6DB;

    .bg-orange {
      background-color: #FBAB7E;
      background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
    }

    &-avatar {
      &:hover {
        cursor: pointer;
      }
    }

    &-name {
      color: $meta-gray;
      font-weight: 400;
    }

    &-email {
      color: $gray-600;
    }

    &-location {
      margin-left: -9px;
    }
  }
}
</style>
